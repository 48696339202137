<template>
    <div class="left-menu oxo-scrollbar">
<!--        <el-row class="tac">-->
<!--            <el-col :span="12">-->
                <el-menu
                        :default-active="$route.path"
                        @open="handleOpen"
                        @close="handleClose"
                        @select="handleSelect"
                        :active="$route.path"
                        :router=false
                        active-text-color="#343434"
                >
<!--                    <el-menu-item index="/console/application">-->
<!--                        <i class="el-icon-menu"></i>-->
<!--                        <span slot="title">{{ this.$i18n.t('UI.components.consoleMenu.menu_1') }}</span>-->
<!--                    </el-menu-item>-->
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="el-icon-discover"></i>
                            <span>{{ $t('Components.ConsoleMenu.Menu1') }}</span>
                        </template>
                        <el-menu-item style="padding-left: 49px" index="/console/pods">{{ $t('Components.ConsoleMenu.Menu1_1') }}</el-menu-item>
                    </el-submenu>
                    <el-submenu index="5">
                        <template slot="title">
                            <i class="el-icon-coin"></i>
                            <span>{{ $t('Components.ConsoleMenu.Menu3') }}</span>
                        </template>
                        <el-menu-item style="padding-left: 49px" index="/console/order">{{ $t('Components.ConsoleMenu.Menu3_1') }}</el-menu-item>
                        <el-menu-item style="padding-left: 49px" index="/console/expense">{{ $t('Components.ConsoleMenu.Menu3_2') }}</el-menu-item>
                        <el-menu-item style="padding-left: 49px" index="/console/transaction">{{ $t('Components.ConsoleMenu.Menu3_3') }}</el-menu-item>
                    </el-submenu>
                    <el-submenu index="90">
                        <template slot="title">
                            <i class="el-icon-user"></i>
                            <span>{{ $t('Components.ConsoleMenu.Menu2') }}</span>
                        </template>
                        <el-menu-item style="padding-left: 49px" index="/console/baseinfo">{{ $t('Components.ConsoleMenu.Menu2_1') }}</el-menu-item>
                        <el-menu-item style="padding-left: 49px" index="/console/modifyPassword">{{ $t('Components.ConsoleMenu.Menu2_2') }}</el-menu-item>
                    </el-submenu>
<!--                    <el-menu-item index="2">-->
<!--                        <i class="el-icon-menu"></i>-->
<!--                        <span slot="title">导航二</span>-->
<!--                    </el-menu-item>-->
<!--                    <el-menu-item index="3" disabled>-->
<!--                        <i class="el-icon-document"></i>-->
<!--                        <span slot="title">导航三</span>-->
<!--                    </el-menu-item>-->
<!--                    <el-menu-item index="4">-->
<!--                        <i class="el-icon-setting"></i>-->
<!--                        <span slot="title">导航四</span>-->
<!--                    </el-menu-item>-->
                </el-menu>
<!--            </el-col>-->
<!--        </el-row>-->
    </div>
</template>

<script>
    export default {
        name: "ConsoleMenu",
        data () {
            return {
                // defaultActive: 1
            }
        },
        methods: {
            // link (xPath) {
            //     if (this.$router.currentRoute.path !== xPath) {
            //         this.$router.push({ path: xPath })
            //     }
            // },
            handleSelect(index) {
                // console.log(index)
                if (this.$router.currentRoute.path !== index) {
                    this.$router.push({ path: index })
                }
            },
            handleOpen() {
                // console.log(key, keyPath);
            },
            handleClose() {
                // console.log(key, keyPath);
            }
        }
    }
</script>

<style>

</style>