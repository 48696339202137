<template>
    <div class="full-screen-app">
        <ConsoleMenu></ConsoleMenu>
        <div class="full-screen-app-container oxo-scrollbar">
            <div class="full-screen-app-content">
                <router-view v-if="isRouterAlive"/>
            </div>
        </div>
    </div>
</template>

<script>
    import ConsoleMenu from '../components/ConsoleMenu'

    export default {
        name: "Console",
        components: {
            ConsoleMenu
        },
        data() {
            return {
                isRouterAlive: true
            }
        },
        provide() {
            return {
                reload: this.reload
            }
        },
        methods: {
            reload() {
                this.isRouterAlive = false
                this.$nextTick(() => {
                    this.isRouterAlive = true
                })
            }
        },
        mounted() {
        }
    }
</script>

<style>

</style>